// ErrorPage.js
import React, { useEffect } from 'react';
import axios from 'axios';
import FormError from '../../components/modals/FormError';

const ErrorPage = () => {
    useEffect(() => {
        // You can also send an update to your backend here if payment was canceled or failed
        const updatePaymentStatus = async () => {
            try {
                const query = new URLSearchParams(window.location.search);
                const userId = query.get('user_id'); // assuming you passed the user ID in the cancel URL
                if (userId) {
                    await axios.post('https://certificatesglobal.com/backend/public/api/htbd-users/add', {
                        id: userId,
                        payment_status: 'failed',
                    });
                }
            } catch (error) {
                console.error('Failed to update payment status', error);
            }
        };

        updatePaymentStatus();
    }, []);

    return (
        <FormError heading="Payment Cancelled" message="Sorry, Your payment was cancelled."/>
    );
};

export default ErrorPage;
