import React, { useContext, useEffect, useReducer, useState } from 'react';
import jobs_reducer from '../reducers/jobs_reducer';
import axios from 'axios';

const defaultState = {
    jobs_loading: false,
    jobs_error: false,
    jobs: [],
    pagination:[],
    filter_jobs: [],
    searchbyjob:'',
}

const JobsContext = React.createContext();

export const JobsProvider = ( { children } ) => {
    const [state, dispatch] = useReducer( jobs_reducer, defaultState );
    const defaultFilters = {
      'Qualification Level': [
        { name: 'UG', selected: false },
        { name: 'PG', selected: false },
      ],
      'Experience Badge': [
        { name: 'Beginner', selected: false },
        { name: 'Experienced', selected: false },
        { name: 'Ranked', selected: false },
      ],
      'Areas of Expertise': [
        { name: 'AI', selected: false },
        { name: 'CS', selected: false },
        { name: 'Accounting ', selected: false },
        { name: 'Design ', selected: false },
        { name: 'Sales & Marketing', selected: false },
      ],
      'Avalability': [
        { name: 'F2F', selected: false },
        { name: 'Remote', selected: false },
        { name: 'Both ', selected: false },
      ],
      'Visa Status': [
        { name: 'Employment visa', selected: false },
        { name: 'Visit in country', selected: false },
        { name: 'Out of country', selected: false },
        { name: 'Resident visa', selected: false },
      ],
      'Proof of Impact': [
        { name: 'Revenue growth', selected: false },
        { name: 'Cost reduction', selected: false },
        { name: 'Project completion', selected: false },
        { name: 'Productivty improvement', selected: false },
        { name: 'Customer satisfaction', selected: false },
        { name: 'Market expansion', selected: false },
        { name: 'Team leadership and development', selected: false },
        { name: 'Innovatiove solution', selected: false },
        { name: 'Effecinncy enhacements', selected: false },
        { name: 'risk management', selected: false },
        { name: 'award recognition', selected: false },
        { name: 'quality improvement', selected: false },
        { name: 'strategic partnerships', selected: false },
        { name: 'crisis management', selected: false },
        { name: 'technological impluicatiopns', selected: false },
      ],
      'Years Of Experience': [
        { name: '> 1 year', selected: false },
        { name: '1-2 years', selected: false },
        { name: '3-5 years', selected: false },
        { name: '< 5 years', selected: false },
        { name: '< 10 years', selected: false },
        { name: '< 15 years', selected: false },
        { name: '< 20 years', selected: false },
      ],
      Language: [
        { name: 'Hindi', selected: false },
        { name: 'English', selected: false },
      ],
      Location: [
        { name: 'UAE', selected: false },
        { name: 'India', selected: false },
        { name: 'Australia', selected: false },
        { name: 'Afghanistan', selected: false },
        { name: 'Albania', selected: false },
        { name: 'Iran', selected: false },
        { name: 'London', selected: false },
      ],
    };
    const [filters, setFilters] = useState(defaultFilters);
    const [page, setPage] = useState(1); // Initialize state
    const baseUrl = "https://certificatesglobal.com/backend/public/api"
    const [search, setSearch] = useState('')
    const [filterString, setFilterString] = useState('');

    const applyFilter = async (filters = {},) => {
        // console.log(filters)
        // setPage(1)
        const { search, filters: filter } = filters
        let qualification_level = filter['Qualification Level'].map((f) => f.selected && f.name).filter(f => f).join(',')
        let experience_badge = filter['Experience Badge'].map((f) => f.selected && f.name).filter(f => f).join(',')
        let expertise_area = filter['Areas of Expertise'].map((f) => f.selected && f.name).filter(f => f).join(',')
        let availability = filter['Avalability'].map((f) => f.selected && f.name).filter(f => f).join(',')
        let visa_status = filter['Visa Status'].map((f) => f.selected && f.name).filter(f => f).join(',')
        let proof_of_impact = filter['Proof of Impact'].map((f) => f.selected && f.name).filter(f => f).join(',')
        let years_of_experience = filter['Years Of Experience'].map((f) => f.selected && f.name).filter(f => f).join(',')
        let language_known = filter['Language'].map((f) => f.selected && f.name).filter(f => f).join(',')
        let location = filter['Location'].map((f) => f.selected && f.name).filter(f => f).join(',')
        let filterString = `full_name=${search}&qualification_level=${qualification_level}&years_of_experience=${years_of_experience}&visa_status=${visa_status}&location=${location}&experience_badge=${experience_badge}&expertise_area=${expertise_area}&availability=${availability}&proof_of_impact=${proof_of_impact}&language_known=${language_known}`
        console.log(filterString)
        setFilterString(filterString)
        fetchJobs(filterString)
      }

        const filterHandler = ({ type, name }) => {
        const updatedFilterByName = filters[type].map((filter) => ({
          ...filter,
          selected: filter.name === name ? !filter.selected : filter.selected,
        }))
        const updatedFilters = {
          ...filters,
          [type]: updatedFilterByName,
        }
        setFilters(updatedFilters)
        applyFilter({ search, filters: updatedFilters })
        setPage(1)
      }

      const fetchJobs = async (filters = '') => {
        dispatch({ type: 'GET_JOBS_BEGIN' });
        try {
            const filterString = filters ? '&' + filters : '';
            const { data } = await axios.get(`${baseUrl}/students?page=${page}${filterString}`);
            if (!data.success) {
                throw new Error('Failed to fetch data');
            }
    
            dispatch({ type: 'GET_JOBS_SUCCESS', payload: { jobsData: data.data.collection, pagination: data.data.pagination } });
        } catch (error) {
            console.error('Error fetching data:', error);
            dispatch({ type: 'GET_JOBS_ERROR' });
        }
    };

    const resetFilters = () => {
      setFilters(defaultFilters);
      setSearch('')
      fetchJobs()
    };
    
    useEffect( () => {
        fetchJobs(filterString)
    }, [page,filterString] );

    const searchHandler = (e) => {
      if (e.target.value.match(/^[a-zA-Z0-9]*$/)) {
        setSearch(e.target.value)
        setPage(1)
        applyFilter({ search: e.target.value, filters }, 1)
      }
    }

    return (
        <JobsContext.Provider value={ { ...state, fetchJobs,page,setPage,filters,filterHandler,setFilters,searchHandler,resetFilters } }>
            { children }
        </JobsContext.Provider>
    )
}

export const useJobsContext = () => {
    return useContext( JobsContext )
}