// SuccessPage.js
import React, { useEffect } from 'react';
import axios from 'axios';
import FormSuccess from '../../components/modals/FormSuccess';

const SuccessPage = () => {
    useEffect(() => {
        // You can also send an update to your backend here, if necessary, for final confirmation
        const updatePaymentStatus = async () => {
            try {
                const query = new URLSearchParams(window.location.search);
                const userId = query.get('user_id'); // assuming you passed the user ID in the success URL
                console.log(userId);
                if (userId) {
                    await axios.post('https://certificatesglobal.com/backend/public/api/htbd-users/add', {
                        id: userId,
                        payment_status: 'success',
                    });
                }
            } catch (error) {
                console.error('Failed to update payment status', error);
            }
        };

        updatePaymentStatus();
    }, []);

    return (
        <FormSuccess heading="Payment Completed" message="Your payment has been successfull." />);
};

export default SuccessPage;
