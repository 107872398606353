import React, { useState } from "react";
import SEO from "../../common/SEO";
import Layout from "../../common/Layout";
import Breadcrumb from "../../common/breadcrumb/Breadcrumb";
import { JobsProvider } from "../../context/jobs_context";
import RegisterStudents from "../../components/job-portal/RegisterStudents";
import JobItemsForFilter from "../../components/job-portal/JobItemsForFilter";
import JobFilters from "../../components/job-portal/JobFilters";
import Skill from "../../components/progressbar/ProgressbarItem";
import TrackVisibility from "react-on-screen";
import CompaniesLogo from "../../components/job-portal/CompaniesLogo";

const JobPortal = () => {
    const [percent, setPercent] = useState(16);

  return (
    <>
      <SEO title="Job Portal" />
      <Layout>
        <Breadcrumb
          title="Job Portal"
          rootUrl="/"
          parentUrl="Home"
          currentUrl="Job Portal"
        />
        <CompaniesLogo/>
        <JobsProvider>
          <div className="edu-course-area edu-section-gapBottom bg-color-white">
            <div className="container">
              <div className="rbt-progress-style-1 row g-5">
                <div className="col-lg-8">
                  <TrackVisibility once className="single-progress">
                    <Skill progress={{ title: "Candidates", percent: percent }} />
                  </TrackVisibility>
                </div>
                <div className="col-lg-4">
                <RegisterStudents />
                </div>
              </div>
              <div className="row g-5">
                <div className="col-lg-8">
                  <JobItemsForFilter />
                </div>
                <div className="col-lg-4">
                  <JobFilters />
                </div>
              </div>
            </div>
          </div>
        </JobsProvider>
      </Layout>
    </>
  );
};

export default JobPortal;
