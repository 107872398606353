import React from 'react';
import { Link } from 'react-router-dom';
import { Parallax } from 'react-scroll-parallax';

const LMS = ( { wrapperClass } ) => {
    return (
        <div className={`home-three-about edu-about-area about-style-4 bg-color-white ${ wrapperClass || 'edu-section-gapBottom' }`}>
            <div className="container eduvibe-animated-shape">
                <div className="row g-lg-5 g-md-5 g-sm-5">
                    <div className="col-lg-12 col-xl-6">
                        <div className="gallery-wrapper">
                            <img src="/images/htbd/your-learning/your-learning.webp" alt="About Images" width="450" height="590" loading="lazy"/>
                            <div className="image-2">
                                <Parallax y={[-25, 15]} tagOuter="figure">
                                    <img src="/images/htbd/your-learning/your-learning-2.webp" alt="About Images" width="150" height="200" loading="lazy"/>
                                </Parallax>
                            </div>
                            <div className="image-3">
                                <Parallax y={[40, -40]} tagOuter="figure">
                                    <img src="/images/htbd/your-learning/your-learning-3.webp" alt="About Images" width="188" height="242" loading="lazy"/>
                                </Parallax>
                            </div>
                        </div>
                    </div>
                    <div className="col-lg-12 col-xl-6">
                        <div className="inner mt_mobile--40">
                            <div className="section-title text-start">
                                <span className="pre-title">A Comprehensive Overview</span>
                                <h3 className="title">Your Learning</h3>
                            </div>
                            <div className="feature-style-6 mt--40">
                                <div className="edu-feature-list color-variation-1">
                                    <div className="icon">
                                        <img src="/images/htbd/your-learning/icons/lms.webp" alt="Icons Images" />
                                    </div>
                                    <div className="content">
                                        <h6 className="title">Centralized Learning Management System (LMS) Overview</h6>
                                        <p className="description">The LMS will host all learning and assessment materials. The evaluation section provides more information on the LMS.</p>
                                    </div>
                                </div>

                                <div className="edu-feature-list color-variation-2">
                                    <div className="icon">
                                        <img src="/images/htbd/your-learning/icons/flexible-learning.webp" alt="Icons Images" />
                                    </div>
                                    <div className="content">
                                        <h6 className="title">Flexible Learning Approach</h6>
                                        <p className="description"> Learning activities will include pre-recorded videos, audio, and written transcripts, along with online group discussions.</p>
                                    </div>
                                </div>
                                
                                <div className="edu-feature-list color-variation-3">
                                    <div className="icon">
                                        <img src="/images/htbd/your-learning/icons/industry-expert.webp" alt="Icons Images" />
                                    </div>
                                    <div className="content">
                                        <h6 className="title">"Engaging Sessions: Insights from Industry Experts</h6>
                                        <p className="description">Engage with industry professionals sharing experiences and lectures on Dubai infrastructure, with live visuals for real-time learning.</p>
                                    </div>
                                </div>

                                <div className="edu-feature-list color-variation-3">
                                    <div className="icon">
                                        <img src="/images/htbd/your-learning/icons/int-business.webp" alt="Icons Images" />
                                    </div>
                                    <div className="content">
                                        <h6 className="title">Live IBP Session: International Business Project</h6>
                                        <p className="description"> The final learning and instructional lecture will be a live session to host International Business Project (IBP).Further details on IBP will be provided in this document.</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                        {/* <div className="read-more-btn mt--30">
                            <Link className="edu-btn" to="#">Know About Us <i className="icon-arrow-right-line-right"></i></Link>
                        </div> */}
                    </div>
                </div>

                <div className="shape-dot-wrapper shape-wrapper d-xl-block d-none">
                    <div className="shape-image shape-image-1">
                        <img src="/images/shapes/shape-03-08.webp" alt="Shape Thumb" />
                    </div>
                    <div className="shape-image shape-image-2">
                        <img src="/images/shapes/shape-27.webp" alt="Shape Thumb" />
                    </div>
                </div>
            </div>
        </div>
    )
}

export default LMS;