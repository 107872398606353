import React, { useEffect, useState } from "react";
import Form from "react-bootstrap/Form";
import Select, { StylesConfig } from "react-select";
import { Modal } from "react-bootstrap";
import FormSuccess from "../modals/FormSuccess";
import FormError from "../modals/FormError";

const Result = () => {
  return (
    <p className="success-message">
      Thanks for your query. We will contact with you soon.
    </p>
  );
};

function RegisterGuests(props) {
  const formStyle = props.formStyle;  
  const [validated, setValidated] = useState(false);
  const [lgShow, setLgShow] = useState(false);
  const handleClose = () => setLgShow(false);
  const handleShow = () => setLgShow(true);
  const [formSubmitted, setFormSubmitted] = useState(false)
  const [formNotSubmitted, setFormNotSubmitted] = useState(false)

  const formDataError = {
    user_name: {
      message: "Enter full name",
    },
    user_email: {
      message: "Enter user_email",
    },
    mobile_no: {
      message: "Enter phone number",
    },
    job_type: {
      message: "Enter years of experience",
    },
    teaching_mode: {
      message: "Select an option",
    },
    availablity: {
      message: "Enter a day",
    },
    time_slot: {
      message: "Enter proof of impact",
    },
    expertise_area: {
      message: "Enter expertise status",
    },
    experience_type: {
      message: "Enter last salary",
    },
    emirates: {
      message: "Enter emirates ",
    },
    profile_picture: {
      message: "Selet your Profile",
    },
    uploaded_cv: {
      message: "Select your Resume",
    },
  };

  const formSubmitSuccessMessage = "Form successfully submitted";

  const [fullNameError, setFullNameError] = useState(false);
  const [emailError, setEmailError] = useState(false);
  // const [linkedInError, setLinkedInError] = useState(false);
  const [phoneError, setPhoneError] = useState(false);
  // const [websiteError, setWebsiteError] = useState(false);
  // const [qualificationError, setQualificationError] = useState(false);
  const [emirateOfCityError, setEmirateOfCityError] = useState(false);
  const [jobTypeError, setJobTypeError] = useState(false);
  const [teachingModeError, setTeachingModeError] = useState(false);
  const [expertiseError, setExpertiseError] = useState(false);
  const [experienceError, setExperienceError] = useState(false);
  const [weekdaySelectorOptions, setWeekdaySelectorOptions] = useState(false);
  const [timeSlotOptionsError, settimeSlotOptionsError] = useState(false);
  const [profilePictureError, setProfilePictureError] = useState(false);
  const [uploadedCvError, setUploadedCvError] = useState(false);

  const [formData, setFormData] = useState({
    user_name: "",
    user_email: "",
    // linked_in_profile: "",
    // personal_website: "",
    // qualification_level: "",
    emirates: "",
    job_type: "",
    availablity: [],
    teaching_mode: "",
    experience_type: "",
    mobile_no: "",
    time_slot: [],
    expertise_area: "",
    profile_picture: "",
    uploaded_cv: "",
  });
  const [countries, setCountries] = useState([]);
  const [selectedExperience, setSelectedExperience] = useState("");
  const [selectedExpertiseAreas, setSelectedExpertiseAreas] = useState([]);
  const [selectedProofOfImpact, setSelectedProofOfImpact] = useState([]);
  const [selectedVisaStatus, setSelectedVisaStatus] = useState("");
  const [profileFileName, setProfileFileName] = useState("");
  const [cvName, setCvName] = useState("");

  const [jobType] = useState([
    "Banker",
    "Buisness Personnel",
    "Designer",
    "E-Commerce",
    "Education",
    "Enginner",
    "Enginnering and Construction",
    "Entertainment and Media",
    "Environmental Conservation",
    "Finance",
    "Freelancer",
    "HealthCare",
    "Hospatility and Tourism",
    "Sustainability and Renewable Energy",
    "Technology",
    "Other",
  ]);

  const WeekdaySelectorOptions = [
    { value: "Monday", label: "Monday" },
    { value: "Tuesday", label: "Tuesday" },
    { value: "Wednesday", label: "Wednesday" },
    { value: "Thursday", label: "Thursday" },
    { value: "Friday", label: "Friday" },
    { value: "Saturday", label: "Saturday" },
    { value: "Sunday", label: "Sunday" },
  ];

  const [weekdaySelectorOptionsSelected, setweekdaySelectorOptionsSelected] =
    useState([]);

  const timeSlotOptions = [
    { value: "9AM-12PM", label: "9AM-12PM" },
    { value: "12PM-3PM", label: "12PM-3PM" },
    { value: "3PM-6PM", label: "3PM-6PM" },
    { value: "6PM-9PM", label: "6PM-9PM" },
  ];

  const [timeSlotOptionsSelected, setTimeSlotOptionsSelected] = useState([]);

  const [ExpertiseStatusOptions] = useState([
    "IT",
    "Buisness",
    "Others",
    // "Employment visa",
  ]);

  const [EmirateOfCity] = useState([
    "Abu Dhabi",
    "Dubai",
    "Sharjah",
    "Umm Al Quwain",
    "Ajman",
    "Ras al Khaimah",
    "Fujairah",
    "London",
  ]);
  const inputHandler = (e) => {
    setFormData((prev) => ({
      ...prev,
      [e.target.name]: e.target.value,
    }));
  };

  const handleSelectChange = (e) => {

    setSelectedExperience(e.target.value);
    setFormData((prev) => ({
      ...prev,
      [e.target.name]: e.target.value,
    }));
  };

  const handleExpertise = (e) => {
    setSelectedExpertiseAreas(e.target.value);
    setFormData((prev) => ({
      ...prev,
      [e.target.name]: e.target.value,
    }));
  };

  const handleProof = (e) => {
    // console.log(e.target.name, e.target.value);
    setSelectedProofOfImpact(e.target.value);
    setFormData((prev) => ({
      ...prev,
      [e.target.name]: e.target.value,
    }));
  };

  const handleVisa = (e) => {
    // console.log(e.target.name, e.target.value);
    setSelectedVisaStatus(e.target.value);
    setFormData((prev) => ({
      ...prev,
      [e.target.name]: e.target.value,
    }));
  };

  function profilePicInputHandler(event) {
    if (event.target.files) {
      setProfileFileName(event.target.files[0].name);
      setFormData((prev) => ({
        ...prev,
        profile_picture: event.target.files[0],
      }));
    }
  }

  function cvInputHandler(event) {
    if (event.target.files) {
      setCvName(event.target.files[0].name);
      setFormData((prev) => ({
        ...prev,
        uploaded_cv: event.target.files[0],
      }));
    }
  }

  const submitFormHandler = async (e) => {
    e.preventDefault();
    e.stopPropagation();
    const form = e.currentTarget;
    let flag = form.checkValidity();

    if (formData.user_name.length < 6) {
      setFullNameError(true);
      flag = flag && false;
    } else {
      setFullNameError(false);
      // props.setShowToast(true);
    }

    if (
      formData.user_email.trim() == "" ||
      !formData.user_email
        .trim()
        .toLowerCase()
        .match(
          /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|.(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
        )
    ) {
      setEmailError(true);
      flag = flag && false;
    } else {
      setEmailError(false);
    }

    if (
      formData.mobile_no.trim() == "" ||
      !formData.mobile_no
        .trim()
        .toLowerCase()
        .match(/^[789]\d{9}$/)
    ) {
      setPhoneError(true);
      flag = flag && false;
    } else {
      setPhoneError(false);
    }

    // if (
    //   formData.linked_in_profile.trim() == "" ||
    //   !formData.linked_in_profile
    //     .trim()
    //     .toLowerCase()
    //     .match(
    //       /(https?:\/\/(www.)|(www.))?linkedin.com\/(mwlite\/|m\/)?in\/[a-zA-Z0-9_.-]+\/?/
    //     )
    // ) {
    //   setLinkedInError(true);
    //   flag = flag && false;
    // } else {
    //   setLinkedInError(false);
    // }

    // if (
    //   formData.personal_website.trim() == "" ||
    //   !formData.personal_website
    //     .trim()
    //     .toLowerCase()
    //     .match(
    //       /^(?:(?:http|https|ftp):\/\/)?[\w.-]+(?:\.[\w\.-]+)+[\w\-._~:/?#[\]@!$&'()*+,;=%]+$/
    //     )
    // ) {
    //   setWebsiteError(true);
    //   flag = flag && false;
    // } else {
    //   setWebsiteError(false);
    // }

    // if (
    //   formData.qualification_level.trim() == "" ||
    //   !formData.qualification_level
    // ) {
    //   setQualificationError(true);
    //   flag = flag && false;
    // } else {
    //   setQualificationError(false);
    // }

    if (formData.emirates.trim() == "" || !formData.emirates) {
      setEmirateOfCityError(true);
      flag = flag && false;
    } else {
      setEmirateOfCityError(false);
    }

    if (formData.job_type.trim() == "" || !formData.job_type) {
      setJobTypeError(true);
      flag = flag && false;
    } else {
      setJobTypeError(false);
    }

    if (formData.teaching_mode.trim() == "" || !formData.teaching_mode) {
      setTeachingModeError(true);
      flag = flag && false;
    } else {
      setTeachingModeError(false);
    }

    if (formData.expertise_area.trim() == "" || !formData.expertise_area) {
      setExpertiseError(true);
      flag = flag && false;
    } else {
      setExpertiseError(false);
    }

    if (weekdaySelectorOptionsSelected.length <= 0) {
      setWeekdaySelectorOptions(true);
      flag = flag && false;
    } else {
      setWeekdaySelectorOptions(false);
    }

    if (timeSlotOptionsSelected.length <= 0) {
      settimeSlotOptionsError(true);
      flag = flag && false;
    } else {
      settimeSlotOptionsError(false);
    }

    if (formData.experience_type.length <= 0) {
      setExperienceError(true);
      flag = flag && false;
    } else {
      setExperienceError(false);
    }

    if (formData.profile_picture == "") {
      setProfilePictureError(true);
      flag = flag && false;
    } else {
      setProfilePictureError(false);
    }

    if (formData.uploaded_cv == "") {
      setUploadedCvError(true);
      flag = flag && false;
    } else {
      setUploadedCvError(false);
    }

    if (flag) {
      try {
        const formDataToSend = new FormData();
        formDataToSend.append("user_name", formData.user_name);
        formDataToSend.append("user_email", formData.user_email);
        formDataToSend.append("mobile_no", formData.mobile_no);
        // formDataToSend.append("linked_in_profile", formData.linked_in_profile);
        // formDataToSend.append("personal_website", formData.personal_website);
        // formDataToSend.append( "qualification_level",formData.qualification_level);
        formDataToSend.append("emirates", formData.emirates);
        formDataToSend.append("job_type", formData.job_type);

        if (weekdaySelectorOptionsSelected.length > 0) {
          for (let i = 0; i < weekdaySelectorOptionsSelected.length; i++) {
            formDataToSend.append(
              `availablity[${i}]`,
              weekdaySelectorOptionsSelected[i].value
            );
          }
        }

        formDataToSend.append("teaching_mode", formData.teaching_mode);
        formDataToSend.append("experience_type", formData.experience_type);

        if (timeSlotOptionsSelected.length > 0) {
          for (let i = 0; i < timeSlotOptionsSelected.length; i++) {
            formDataToSend.append(
              `time_slot[${i}]`,
              timeSlotOptionsSelected[i].value
            );
          }
        }
        formDataToSend.append("expertise_area", formData.expertise_area);
        formDataToSend.append("profile_picture", formData.profile_picture);
        formDataToSend.append("uploaded_cv", formData.uploaded_cv);

        // alert('Thank You for submitting')
        console.log("sending in api ->", formDataToSend);

        // Check if formDataToSend has data
        if (formDataToSend && formDataToSend.get("user_email")) {
          // console.log(formDataToSend);
          const response = await fetch(
            "https://certificatesglobal.com/backend/public/api/teacher/add",
            {
              method: "POST",
              body: formDataToSend,
            }
          );

          const responseData = await response.json();
          if (responseData.success) {
            // props.setShowToast(true);
            handleClose()
            setFormSubmitted(true)
          }
          console.log("API Response:", responseData);
        } else {
          setFormNotSubmitted(true)
          console.log("Form data is not complete. Cannot send request.");
        }
      } catch (error) {
        setFormNotSubmitted(true)
        console.error("Error while submitting form:", error);
      }
    }
  };

  useEffect(() => {
    const fetchCountries = async () => {
      try {
        const response = await fetch(
          "https://certificatesglobal.com/backend/public/api/countries"
        );
        if (!response.ok) {
          throw new Error("Failed to fetch countries");
        }
        const countryData = await response.json();
        setCountries(countryData.data);
      } catch (error) {
        console.error("Error fetching countries:", error);
      }
    };

    fetchCountries();
  }, []);

 const customeSelect: StylesConfig = {
  control: (styles) => {
    // console.log('styles', styles);
    return {
      ...styles,
      backgroundColor: "white",
      borderRadius: 12,
      minHeight: 32,
      fontSize: 13,

      ":hover": {
        color: "var(--bs-body-color)",
        backgroundColor: "var(--bs-body-bg)",
        borderColor: "#86b7fe",
        outline: 0,
        boxShadow: "0 0 0 0.25rem rgba(13, 110, 253, 0.25)",
      },
      ":active": {
        color: "var(--bs-body-color)",
        backgroundColor: "var(--bs-body-bg)",
        borderColor: "#86b7fe",
        outline: 0,
        boxShadow: "0 0 0 0.25rem rgba(13, 110, 253, 0.25)",
      },
      ":focus": {
        color: "var(--bs-body-color)",
        backgroundColor: "var(--bs-body-bg)",
        borderColor: "#86b7fe",
        outline: 0,
        boxShadow: "0 0 0 0.25rem rgba(13, 110, 253, 0.25)",
      },
      ":visited": {
        color: "var(--bs-body-color)",
        backgroundColor: "var(--bs-body-bg)",
        borderColor: "#86b7fe",
        outline: 0,
        boxShadow: "0 0 0 0.25rem rgba(13, 110, 253, 0.25)",
      },
    };
  },
  input: (styles) => {
    // console.log("input-styles", styles);
    styles = { ...styles, paddingTop: 0, paddingBottom: 0, margin: 0 };
    // console.log("input-styles", styles);
    return styles;
  },
  option: (styles, { data, isDisabled, isFocused, isSelected }) => {

    // console.log('option-styles', styles)
    return {
      ...styles,

      color: "black",
      cursor: isDisabled ? "not-allowed" : "default",
      ":active": {
        ...styles[":active"],
        backgroundColor: "var(--bs-primary)",
      },
    };
  },
};

  return (
    <>
      <div className="container pb-5">
        <div className="row g-5 align-items-center">
          <div className="col-lg-12">
            <div className="read-more-btn text-end">
              <button className="edu-btn" onClick={handleShow}>
                Register<i className="icon-arrow-right-line-right"></i>
              </button>
            </div>
          </div>
        </div>
      </div>

      <Modal show={lgShow} onHide={handleClose} centered size="lg">
        <Modal.Header className="bg-color-white ">
          {/* <h4 className="title " style={{fontSize:"25px"}}>Guest Registeration Form</h4> */}
          <img src="/images/guest-lecture.webp" style={{width:"100%"}}></img>
          {/* <Modal.Title>Register Guest</Modal.Title> */}
        </Modal.Header>
        <Modal.Body>
          <Form
            className={`${formStyle}`}
            action=""
            validated={validated}
            onSubmit={submitFormHandler}
          >
            <div className="container ">
              <div className="row">
                <div className="col-md-6 pt-3 ">
                  <label
                    htmlFor="validationCustom01"
                    className="form-label"
                    required
                  >
                    Full Name *
                  </label>
                  <input
                    type="text"
                    className="form-control form-control-lg"
                    placeholder="Name"
                    onChange={inputHandler}
                    name="user_name"
                    required
                  />
                  {fullNameError && (
                    <span className="text-danger">
                      {formDataError["user_name"]["message"]}
                    </span>
                  )}
                </div>

                <div className="col-md-6 pt-3">
                  <label className="form-label">Email *</label>
                  <input
                    type="email"
                    className="form-control form-control-lg"
                    placeholder="Email"
                    required
                    onChange={inputHandler}
                    name="user_email"
                  />
                  {emailError && (
                    <span className="text-danger">
                      {formDataError["user_email"]["message"]}
                    </span>
                  )}
                </div>

                <div className="col-md-6 pt-3">
                  <label className="form-label">Mobile No. *</label>
                  <input
                    type="text"
                    className="form-control form-control-lg"
                    placeholder="Phone No."
                    required
                    onChange={inputHandler}
                    name="mobile_no"
                  />
                  {phoneError && (
                    <span className="text-danger">
                      {formDataError["mobile_no"]["message"]}
                    </span>
                  )}
                </div>

                <div className="col-md-6 pt-3">
                  <label className="form-label">Job Type *</label>
                  <select
                    name="job_type"
                    className="form-control form-control-lg rounded-4"
                    onChange={handleSelectChange}
                  >
                    <option value="">Select...</option>
                    {jobType.map((option, index) => (
                      <option key={index} value={option}>
                        {option}
                      </option>
                    ))}
                  </select>
                  {jobTypeError && (
                    <span className="text-danger">
                      {formDataError["job_type"]["message"]}
                    </span>
                  )}
                </div>

                <div className="col-md-6 pt-3">
                  <label className="pb-2">Teaching Mode *</label>
                  <select
                    // className="form-label"
                    name="teaching_mode"
                    onChange={inputHandler}
                    className="form-control form-control-lg rounded-4"
                  >
                    <option value="">Select...</option>

                    <option>Face to Face</option>
                    <option>Online</option>
                  </select>

                  {teachingModeError && (
                    <span className="text-danger">
                      {formDataError["teaching_mode"]["message"]}
                    </span>
                  )}
                </div>

                <div className="col-md-6 pt-3">
                  <label className="form-label">Select Expertise *</label>
                  <select
                    name="expertise_area"
                    onChange={handleVisa}
                    value={selectedVisaStatus}
                    className="form-control form-control-lg rounded-4"
                  >
                    <option value="">- Select Expertise -</option>
                    {ExpertiseStatusOptions.map((option, index) => (
                      <option key={index} value={option}>
                        {option}
                      </option>
                    ))}
                  </select>
                  {expertiseError && (
                    <span className="text-danger">
                      {formDataError["expertise_area"]["message"]}
                    </span>
                  )}
                </div>
                <div className="col-md-6 pt-3">
                  <label>Choose Availabitlity *</label>

                  <Select
                    options={WeekdaySelectorOptions}
                    isMulti
                    onChange={(value) => {
                      setweekdaySelectorOptionsSelected(value);
                    }}
                    styles={customeSelect}
                  ></Select>
                  {weekdaySelectorOptions && (
                    <span className="text-danger">
                      {formDataError["availablity"]["message"]}
                    </span>
                  )}
                </div>

                <div className="col-md-6 pt-3">
                  <label>Choose Time Slot *</label>

                  <Select
                    options={timeSlotOptions}
                    isMulti
                    value={timeSlotOptionsSelected}
                    onChange={(value) => {
                      setTimeSlotOptionsSelected(value);
                    }}
                    styles={customeSelect}
                  ></Select>
                  {timeSlotOptionsError && (
                    <span className="text-danger">
                      {formDataError["time_slot"]["message"]}
                    </span>
                  )}
                </div>


                <div className="col-md-6 pt-3">
                  <label className="form-label">Experience *</label>
                  <input
                    type="text"
                    placeholder="Enter your Experience"
                    name="experience_type"
                    onChange={inputHandler}
                    required
                  />
                  {experienceError && (
                    <span className="text-danger">
                      {formDataError["experience_type"]["message"]}
                    </span>
                  )}
                </div>

                <div className="col-md-6 pt-3">
                  <label className="form-label"> Select Emirate/City *</label>
                  <select
                    name="emirates"
                    // style={{ padding: "0.6rem" }}
                    onChange={inputHandler}
                    className="form-control form-control-lg rounded-4"
                  >
                    <option val="">- Select Emirate/City -</option>
                    {EmirateOfCity.map((option, index) => (
                      <option key={index} value={option}>
                        {option}
                      </option>
                    ))}
                  </select>
                  {emirateOfCityError && (
                    <span className="text-danger">
                      {formDataError["emirates"]["message"]}
                    </span>
                  )}
                </div>

                <div className="col-md-6 pt-3">
                  <div className="form-label">
                    <label className="mb-1">Upload Profile *</label>
                    <input
                      className="form-control form-control-lg rounded-4"
                      type="file"
                      name="profile_picture"
                      onChange={profilePicInputHandler}
                    />
                  </div>
                  {profilePictureError && (
                    <span className="text-danger">
                      {formDataError["profile_picture"]["message"]}
                    </span>
                  )}
                </div>

                <div className="col-md-6 pt-3">
                  <div className="form-label">
                    <label className="mb-1">Upload Resume *</label>
                    <input
                      className="form-control form-control-lg rounded-4"
                      type="file"
                      name="uploaded_cv"
                      placeholder="Subject"
                      onChange={cvInputHandler}
                    />
                  </div>
                  {uploadedCvError && (
                    <span className="text-danger">
                      {formDataError["uploaded_cv"]["message"]}
                    </span>
                  )}
                </div>
              </div>
            </div>
          </Form>
        </Modal.Body>
        <Modal.Footer className=" px-5">
          <button
            style={{ background: "#dc3545" }}
            className="edu-btn"
            onClick={handleClose}
          >
            Close
          </button>
          <button
            className="edu-btn ms-4"
            onClick={submitFormHandler ? submitFormHandler : undefined}
          >
            Register<i className="icon-arrow-right-line-right"></i>
          </button>
        </Modal.Footer>
      </Modal>

      {formSubmitted && 
     <FormSuccess heading="Thank You !" message="You have been successfully registered."/>}

      {formNotSubmitted && 
     <FormError heading="Something went wrong !" message="Sorry, there was an error submitting the form. <br/> Please try again later."/>
     }
    </>
  );
}
export default RegisterGuests;
