import { BrowserRouter as Router, Routes } from "react-router-dom";
import { Route } from "react-router-dom";
import Home from "./pages/home/Home";
import ScrollToTop from "./components/scrolltotop/ScrollToTop";
// Import Css Here 
import './assets/scss/style.scss';
import AboutUs from "./pages/about/AboutUs";
import HTBD from "./pages/htbd/HTBD";
import SEDNews from "./pages/sed-news/SEDNews";
import BlogDetails from "./components/sed-news/BlogDetails";
import GuestLecture from "./pages/guest-lecture/GuestLecture";
import JobPortal from "./pages/jobportal/JobPortal";
import LoginRegister from "./pages/innerpages/LoginRegister";
import ContactUs from "./pages/contact/ContactUs";
import Error from "./pages/innerpages/Error";
import HomeTwoEvents from "./components/sed-category/HomeTwoEvents";
import SnackbarProvider from 'react-simple-snackbar';
// import SomeChildComponent from './SomeChildComponent';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import SuccessPage from "./pages/payment/SuccessPage";
import ErrorPage from "./pages/payment/ErrorPage";
// require('dotenv').config();
// import dotenv from 'dotenv';

function App() {
  return (
    <Router>
      <SnackbarProvider>
        <ScrollToTop>
        <ToastContainer />
          <Routes>
            <Route exact path="/" element={<Home />} />
            <Route exact path="/about" element={<AboutUs />} />
            <Route exact path="/htbd" element={<HTBD />} />
            <Route exact path="/sed-news" element={<SEDNews />} />
            <Route exact path="/guest-lecture" element={<GuestLecture />} />
            <Route exact path="/job-portal" element={<JobPortal />} />
            <Route exact path="/contact" element={<ContactUs />} />
            <Route exact path={`${process.env.PUBLIC_URL + '/blog-details-right-sidebar/:id'}`} element={<BlogDetails />} />
            <Route exact path={`${process.env.PUBLIC_URL + '/login-register'}`} element={<LoginRegister />} />
            <Route path='/category-product/:category' element={<HomeTwoEvents />} />
            <Route path='/success' element={<SuccessPage />} />
            <Route path='/cancel' element={<ErrorPage />} />
            <Route path='*' element={<Error />} />
          </Routes>
        </ScrollToTop>
      </SnackbarProvider>
    </Router>
  );
}

export default App;
