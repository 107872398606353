import React from "react";
import SEO from "../../common/SEO";
import Header from "../../common/header/Header";
import FooterOne from "../../common/footer/Footer";
import Banner from "../../components/banner/Banner";
import HomeCategory from "../../components/home/HomeCategory";
import HomeCategories from "../../components/home/HomeCategories";
import TestimonialSectionOne from "../../components/testimonial-section/TestimonialSectionOne";
import WhyChooseUsSection from "../../components/why-choose-us-section/WhyChooseUsSection";
import NewsLettterOne from "../../components/newsletter/NewsLettterOne";
import HomeBlog from "../../components/home/HomeBlog";
import LogoOne from "../../components/home/LogoOne";
import AboutOne from "../../components/home/AboutOne";
import HomeHTBD from "../../components/home/HomeHTBD";

const Home = () => {
  const preload = [
    "/images/banner/main/banner-brain.webp",
    "/images/banner/main/girl-bg.webp"
  ]
  return (
    <>
      <SEO title="Home" preload={preload} />

      <Header styles="header-transparent header-style-2" />

      <Banner />

      <AboutOne />

      <HomeCategory />

      <HomeCategories />

      <HomeHTBD />

      <LogoOne />

      <TestimonialSectionOne />

      <WhyChooseUsSection />

      <NewsLettterOne />

      <HomeBlog />

      <FooterOne />
    </>
  );
};

export default Home;
