import { React, useEffect, useState } from 'react';
import axios from 'axios';
import Slider from "react-slick";

const CompaniesLogo = ({ separator }) => {

    const baseUrl = "https://certificatesglobal.com/backend/public/api"
    const [companiesLogo, setCompaniesLogo] = useState([]);

    const getCompaniesLogo = async () => {
        try {
            const { data } = await axios.get(`${baseUrl}/settings/companies-logo/job-portal`)
            console.log(data.data)
            setCompaniesLogo(data.data)
        }
        catch (error) {
            console.log(error)
        }
    }

    useEffect(() => {
        getCompaniesLogo();
    }, []);

    var settings = {
        dots: false,
        infinite: true,
        speed: 500,
        slidesToShow: 5,
        slidesToScroll: 5,
        responsive: [
            {
                breakpoint: 1024,
                settings: {
                    slidesToShow: 4,
                    slidesToScroll: 4
                }
            },
            {
                breakpoint: 992,
                settings: {
                    slidesToShow: 3,
                    slidesToScroll: 3
                }
            },
            {
                breakpoint: 768,
                settings: {
                    slidesToShow: 3,
                    slidesToScroll: 3
                }
            }
        ]
    };

    return (
        <div className={`edu-brand-area pt--30 bg-color-white `}>
            <div className="container">
                <div className="row eduvibe-home-five-brands">
                    {/* <div className="col-lg-2 col-md-12">
                        <div className="client-logo">
                            <h6>Top Companies </h6>
                        </div>
                    </div> */}
                    <Slider {...settings}>
                        {
                            companiesLogo.map((companyLogo) => (
                                <div className="col-lg-2 col-md-4 col-sm-6 col-6" key={companyLogo.id}>
                                    <a href="#" className="client-logo">
                                        <img className="logo-main" src={companyLogo.logo} alt="Company Logo" width="150"/>
                                        <img className="logo-hover" src={companyLogo.logo} alt="Company Logo" width="150"/>
                                    </a>
                                </div>
                            ))
                        }
                    </Slider>

                </div>
            </div>
        </div>
    )
}

export default CompaniesLogo;