import React from 'react';
import { Link } from 'react-router-dom';
import { IoBriefcaseOutline } from "react-icons/io5";
import { FaLocationDot } from "react-icons/fa6";

const GuestTypeThree = ({ data, classes, bgWhite }) => {

    const availablity = data.availablity.split(",")
    console.log(data)
    return (
        <div className={`edu-card card-type-1 radius-small ${classes ? classes : ''} ${bgWhite === 'enable' ? 'bg-white' : ''}`}>
            <div className="inner">
                <div className="thumbnail">
                    <Link to="#">
                        <img className="w-100" src={data.profile_pic} alt="Course Thumb" width={data.image_width} height={data.image_height} />
                    </Link>
                    <div className="top-position status-group left-top">
                        <span className="eduvibe-status status-01">{data.job_type}</span>
                    </div>
                    {/* <div className="wishlist-top-right">
                        <button className="wishlist-btn"><i className="icon-Heart"></i></button>
                    </div> */}

                </div>
                <div className="content">
                    <ul className="edu-meta meta-01">
                        <li><i className="icon-file-list-4-line"></i><b>Mode:</b>&nbsp;{`${data.teaching_mode}`}</li>
                        {
                            data.experience === null ? (
                                <li><IoBriefcaseOutline />&nbsp; Fresher</li>
                            )
                                : (

                                    <li><IoBriefcaseOutline />&nbsp;{data.experience}</li>
                                )
                        }
                    </ul>
                    <h6 className="title">
                        {data.user_name}
                    </h6>
                    <div className="edu-rating rating-default">
                        <div className="rating eduvibe-course-rating-stars">
                            {data.ratings !== null
                                ? Array.from({ length: 5 }, (_, index) => (
                                    <img
                                        key={index}
                                        src={
                                            index < data.ratings
                                                ? "images/icons/icon-star-active.png" // Filled star image
                                                : "images/icons/icon-star.png" // Empty star image
                                        }
                                        height="20"
                                        width="20"
                                        className="ps-1"
                                        alt="star"
                                    />
                                ))
                                : // If ratings are null, show 5 empty stars
                                Array.from({ length: 5 }, (_, index) => (
                                    <img
                                        key={index}
                                        src="images/icons/icon-star.png"
                                        height="20"
                                        width="20"
                                        className="ps-1"
                                        alt="star"
                                    />
                                ))}
                        </div>

                    </div>
                    <div className='mb-5'>
                        {availablity.map((days) => {
                            return (
                                <button className="btn btn-lg bg-light m-2" key={days}>{days}</button>
                            )
                        })}
                    </div>
                    <div className="card-bottom">
                        <div className="price-list price-style-03">
                            {/* {
                                data.price === '0' ?
                                    <div className="price current-price">Free</div>
                                :
                                    <div className="price current-price"> {data.emirates}</div>
                            }
                            { 
                                data.oldPrice && <div className="price old-price">${data.oldPrice}</div> 
                            } */}
                            <div className="price current-price"><FaLocationDot /> {data.emirates}</div>

                        </div>
                        <ul className="edu-meta meta-01">
                            <li><i className="icon-account-circle-line"></i>{data.gender}</li>
                        </ul>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default GuestTypeThree;