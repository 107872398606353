import React, { useState, useEffect } from 'react';
import { Modal, Form } from 'react-bootstrap';
import { loadStripe } from '@stripe/stripe-js';
import axios from 'axios';
import FsLightbox from 'fslightbox-react';
import FormSuccess from '../modals/FormSuccess';
import FormErrorModal from '../modals/FormError';
import { Link } from 'react-router-dom';
import { FaPlay } from 'react-icons/fa';
import PhoneInput from 'react-phone-input-2'
import 'react-phone-input-2/lib/style.css'
import {
    CountrySelect,
    StateSelect,
} from "react-country-state-city";
import "react-country-state-city/dist/react-country-state-city.css";

//React hook form
import { useForm, SubmitHandler } from "react-hook-form"


const STRIPE_PUBLIC_KEY = process.env.REACT_APP_STRIPE_PUBLIC_KEY;
const stripePromise = loadStripe(STRIPE_PUBLIC_KEY);

const HTBDBanner = () => {
    const [toggler, setToggler] = useState(false);
    const [show, setShow] = useState(false);
    const [formData, setFormData] = useState({ name: '', email: '', phone: '', country: '', state: '' });
    const [FormError, setFormError] = useState({});
    const [isProcessing, setIsProcessing] = useState(false);
    const [paymentSuccess, setPaymentSuccess] = useState(false);
    const [paymentError, setPaymentError] = useState(false);
    const [countryid, setCountryid] = useState(0);
    const [stateid, setstateid] = useState(0);

    // React hook form
    const {
        register,
        handleSubmit,
        watch,
        formState: { errors },
    } = useForm()

    const onSubmit = (data) => console.log("submitting", data)


    const videoLink = [
        'https://www.youtube.com/watch?v=xZZ-wSo0jak&ab_channel=SeedingBrains-TQUK'
    ];

    // Function to handle modal visibility based on URL hash
    const checkHashAndShowModal = () => {
        const hash = window.location.hash;
        if (hash === '#success') {
            setPaymentError(false);
            setPaymentSuccess(true);
            removeHashFromUrl(); // Remove the hash after showing the modal
        } else if (hash === '#cancel') {
            setPaymentSuccess(false);
            setPaymentError(true);
            removeHashFromUrl(); // Remove the hash after showing the modal
        }
    };

    // Function to remove the hash from the URL
    const removeHashFromUrl = () => {
        if (window.history.replaceState) {
            window.history.replaceState(null, '', window.location.pathname);
        }
    };

    useEffect(() => {
        // Check the hash initially when the component mounts
        checkHashAndShowModal();

        // Add event listener to check for hash changes
        window.addEventListener('hashchange', checkHashAndShowModal);

        // Cleanup event listener on unmount
        return () => {
            window.removeEventListener('hashchange', checkHashAndShowModal);
        };
    }, []);

    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);

    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData({
            ...formData,
            [name]: value,
        });
        setFormError((prevErrors) => ({
            ...prevErrors,
            [name]: '', // Clear field-specific errors on input change
        }));
    };

    const validateForm = () => {
        const errors = {};

        // Name validation
        if (!formData.name || formData.name.trim() === "") {
            errors.name = "Name is required";
        } else if (typeof formData.name !== "string") {
            errors.name = "Name must be a string";
        } else if (formData.name.length < 2) {
            errors.name = "Name must be at least 2 characters";
        }

        const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
        if (!formData.email.trim()) {
            errors.email = "Email is required.";
        } else if (!emailRegex.test(formData.email)) {
            errors.email = "Invalid email format.";
        }

        const phoneRegex = /^\+?[0-9]{7,15}$/;
        if (!formData.phone.trim()) {
            errors.phone = "Phone number is required.";
        } else if (!phoneRegex.test(formData.phone)) {
            errors.phone = "Invalid phone number.";
        }

        return errors;
    };

    const handlePayment = async () => {
        console.log(formData);
        // Frontend validation
        const formErrors = validateForm();
        if (Object.keys(formErrors).length > 0) {
            setFormError(formErrors);
            return;
        }

        setIsProcessing(true);
        const randomNumber = Math.ceil(Math.random() * 111111111111111111);

        try {
            // Backend call: Add user
            const userResponse = await axios.post('http://127.0.0.1:8000/api/htbd-users/add', {
                user_name: formData.name,
                user_email: formData.email,
                request_id: randomNumber,
            });
            console.log(userResponse);

            if (!userResponse.data.success) {
                const backendErrors = userResponse.data.errors || {};
                const errorMessages = {};

                Object.keys(backendErrors).forEach((key) => {
                    errorMessages[key] = backendErrors[key][0];
                });

                setFormError(errorMessages);
                return;
            }

            const userId = userResponse.data.data.id;

            // Backend call: Create Checkout Session
            const stripe = await stripePromise;
            if (!stripe) throw new Error('Stripe not initialized');

            const checkoutResponse = await axios.post('http://127.0.0.1:8000/api/create-checkout-session', {
                amount: 5000, // $50
                user_email: formData.email,
                request_id: randomNumber,
                userId: userId,
                success_url: `${window.location.origin}/htbd/#success`,
                cancel_url: `${window.location.origin}/htbd/#cancel`,
            });

            if (!checkoutResponse.data.success) {
                throw new Error(checkoutResponse.data.message || 'Failed to create checkout session');
            }

            const sessionId = checkoutResponse.data.data.sessionId;
            const { error } = await stripe.redirectToCheckout({ sessionId });

            if (error) throw new Error(error.message);
        } catch (error) {
            console.error('Payment error:', error.message);
            alert(`Error: ${error.message}`);
        } finally {
            setIsProcessing(false);
        }
    };

    return (
        <>
            {/* Modal for user details */}
            <Modal show={show} onHide={handleClose} centered size="lg">
                {/* <Modal.Header closeButton>
                    <Modal.Title>Provide your details</Modal.Title>
                </Modal.Header> */}
                <Modal.Body>
                    <Modal.Header closeButton>
                        <Modal.Title>Provide your details</Modal.Title>
                    </Modal.Header>
                    <Form>
                        <div className="container">
                            <div className="row">
                                <div className="col-md-12 pt-3">
                                    <label htmlFor="name" className="form-label">Name *</label>
                                    <input
                                        type="text"
                                        className="form-control form-control-lg"
                                        placeholder="Name"
                                        name="name"
                                        value={formData.name}
                                        onChange={handleChange}
                                    />
                                    {FormError.name && (
                                        <span className="text-danger">{FormError.name}</span>
                                    )}
                                </div>
                                <div className="col-md-12 pt-3">
                                    <label htmlFor="email" className="form-label">Email *</label>
                                    <input
                                        type="email"
                                        className="form-control form-control-lg"
                                        placeholder="Email"
                                        name="email"
                                        value={formData.email}
                                        onChange={handleChange}
                                    />
                                    {FormError.email && (
                                        <span className="text-danger">{FormError.email}</span>
                                    )}
                                </div>
                                {/* <div className="col-md-6 pt-3">
                                    <label htmlFor="country" className="form-label">Country *</label>
                                    <CountrySelect
                                        onChange={(e) => {
                                            setCountryid(e.id);
                                            setFormData({ ...formData, country: e.name });
                                        }}
                                        placeHolder="Select Country"
                                    />
                                    {FormError.phone && (
                                        <span className="text-danger">{FormError.phone}</span>
                                    )}
                                </div>
                                <div className="col-md-6 pt-3">
                                    <label htmlFor="state" className="form-label">State *</label>
                                    <StateSelect
                                        countryid={countryid}
                                        onChange={(e) => {
                                            setstateid(e.id);
                                            setFormData({ ...formData, state: e.name });
                                        }}
                                        placeHolder="Select State"
                                    />
                                    {FormError.phone && (
                                        <span className="text-danger">{FormError.phone}</span>
                                    )}
                                </div>
                                <div className="col-md-12 pt-3">
                                    <label htmlFor="phone" className="form-label">Phone *</label>
                                    <PhoneInput
                                        country={'in'}
                                        value={formData.phone}
                                        onChange={phone => setFormData({ ...formData, phone })}
                                        inputProps={{
                                            name: 'phone',
                                            required: true,
                                            autoFocus: true
                                        }}
                                    />
                                    {FormError.phone && (
                                        <span className="text-danger">{FormError.phone}</span>
                                    )}
                                </div> */}
                            </div>
                        </div>
                    </Form>
                    {/* <form onSubmit={handleSubmit(onSubmit)}>
                        <input defaultValue="test" {...register("example")} />

                        <input {...register("email", { required: true })} />
                        {errors.email && <span>This field is required</span>}

                        <div className="d-flex">
                            <button
                                type="submit"
                                className="edu-btn te"
                                disabled={isProcessing}
                            >
                                {isProcessing ? 'Processing...' : 'Pay $50'}
                            </button>
                            </div>
                    </form> */}

                </Modal.Body>
                <Modal.Footer>
                    <button
                        onClick={handlePayment}
                        className="edu-btn"
                        disabled={isProcessing}
                    >
                        {isProcessing ? 'Processing...' : 'Pay $50'}
                    </button>
                </Modal.Footer>
            </Modal>

            {/* Page Content */}
            <>
                <div className="edu-service-area service-wrapper-3">
                    <div className="about-us-two-service-wrapper edu-section-gapTop bg-image position-relative">
                        <div className="eduvibe-about-us-two-service">
                            <div className="container eduvibe-animated-shape">
                                <div className="row">
                                    <div className="col-lg-12">
                                        {/* <SectionTitle
                                        classes = "text-center"
                                        slogan = "HTBD"
                                        title = "How To Do Business In Dubai"
                                    /> */}
                                        <div className='section-title text-center'>
                                            <img src='/images/htbd/htbd.webp' /><br />
                                            {/* <span className="pre-title">HTBD</span> */}
                                            <h1 className="title">How To Do Business In Dubai</h1>
                                            <p className='my-5'>Department - Seeding Brains RSDs Mr. Chinmay Chitransh - Program manager</p>
                                            <button className='edu-btn mb-4' onClick={handleShow}>Register</button>
                                        </div>
                                    </div>
                                </div>

                                {/* <FeatureTwo /> */}

                                <div className="shape-dot-wrapper shape-wrapper d-xl-block d-none">
                                    <div className="shape-image shape-image-1">
                                        <img src="/images/shapes/shape-29.webp" alt="Shape Thumb" />
                                    </div>
                                    <div className="shape-image shape-image-2">
                                        <img src="/images/shapes/shape-02-06.webp" alt="Shape Thumb" />
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className="eduvibe-about-us-two-video">
                            <div className="container eduvibe-animated-shape">
                                <div className="row">
                                    <div className="col-lg-10 offset-lg-1">
                                        <div className="video-gallery-wrapper edu-section-gapTop video-section-overlayto-another">
                                            <div className="video-gallery-1">
                                                <div className="thumbnail video-popup-wrapper">
                                                    <img className="radius-small w-100" src={`/images/htbd/htbd-video-banner.webp`} alt="Video PopUp Thumb" />
                                                    <button className="video-play-btn with-animation position-to-top video-popup-activation btn-secondary-color size-80" onClick={() => setToggler(!toggler)}>
                                                        <span><FaPlay className="play-icon" /></span>
                                                    </button>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                <div className="shape-dot-wrapper shape-wrapper d-xl-block d-none">
                                    <div className="shape-image shape-image-1">
                                        <img src="/images/shapes/shape-07-01.webp" alt="Shape Thumb" />
                                    </div>
                                    <div className="shape-image shape-image-2">
                                        <img src="/images/shapes/shape-03-02.webp" alt="Shape Thumb" />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <FsLightbox
                    toggler={toggler}
                    sources={videoLink}
                    maxYoutubeVideoDimensions={{ width: 900, height: 550 }}
                />
            </>

            {/* Modal for success or error */}
            {paymentSuccess && <FormSuccess heading="Payment Completed" message="Your payment has been successfull." />}
            {paymentError && <FormErrorModal heading="Payment Failed" message="Your payment was failed." />}
        </>
    );
};

export default HTBDBanner;
