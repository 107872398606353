import React from 'react';
import { Link } from 'react-router-dom';

const Breadcrumb = ({ title, rootUrl, parentUrl, currentUrl }) => {
    return (
        <div className="edu-breadcrumb-area breadcrumb-style-1 ptb--60 ptb_md--40 ptb_sm--40 bg-image">
            <div className="container eduvibe-animated-shape">
                <div className="row">
                    <div className="col-lg-12">
                        <div className="breadcrumb-inner text-start">
                            <div className="page-title">
                                <h3 className="title" dangerouslySetInnerHTML={{ __html: title }}></h3>
                            </div>
                            <nav className="edu-breadcrumb-nav">
                                <ol className="edu-breadcrumb d-flex justify-content-start liststyle">
                                    <li className="breadcrumb-item"><Link to={`${rootUrl}`} dangerouslySetInnerHTML={{ __html: parentUrl }}></Link></li>
                                    <li className="separator"><i className="ri-arrow-drop-right-line"></i></li>
                                    <li className="breadcrumb-item active" dangerouslySetInnerHTML={{ __html: currentUrl }}></li>
                                </ol>
                            </nav>
                        </div>
                    </div>
                </div>
                {/* <div class="row g-5">
                    <div class="col-lg-12">
                        <div class="section-title">
                            <h3 class="title">Our Experts</h3>
                            <p>Welcome to Seeding Brains Job Portal, where we connect top talent with forward-thinking employers.
                                The Seeding Brains Job Portal is your bridge from education to employment. Designed to help you
                                leverage your learning, this platform connects you with top employers in Dubai and beyond. Build your
                                profile, showcase your expertise, and apply your skills to real-world challenges as you take the next step
                                in your career journey. Join our portal today and start turning your knowledge into impact.</p>
                        </div>
                    </div>
                </div> */}

                <div className="shape-dot-wrapper shape-wrapper d-xl-block d-none">
                    <div className="shape-image shape-image-1">
                        <img src="/images/shapes/shape-11-07.webp" alt="Shape Thumb" />
                    </div>
                    <div className="shape-image shape-image-2">
                        <img src="/images/shapes/shape-01-02.webp" alt="Shape Thumb" />
                    </div>
                    <div className="shape-image shape-image-3">
                        <img src="/images/shapes/shape-03.webp" alt="Shape Thumb" />
                    </div>
                    <div className="shape-image shape-image-4">
                        <img src="/images/shapes/shape-13-12.webp" alt="Shape Thumb" />
                    </div>
                    <div className="shape-image shape-image-5">
                        <img src="/images/shapes/shape-36.webp" alt="Shape Thumb" />
                    </div>
                    <div className="shape-image shape-image-6">
                        <img src="/images/shapes/shape-05-07.webp" alt="Shape Thumb" />
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Breadcrumb;